var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "introduction" }, [
    _c(
      "div",
      { staticClass: "intro-img" },
      [
        _vm._l(_vm.detailData.attachList, function (item, i) {
          return _c("el-image", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentImgIndex == i,
                expression: "currentImgIndex==i",
              },
            ],
            key: i,
            staticStyle: { border: "1px solid #EEE" },
            attrs: { src: item, alt: "", fit: "contain" },
          })
        }),
        _c(
          "div",
          { staticClass: "thumbnail" },
          _vm._l(_vm.detailData.attachList, function (item, i) {
            return _c("el-image", {
              key: i,
              class: { img: true, active: i == _vm.currentImgIndex },
              attrs: { src: item, fit: "contain", alt: "" },
              on: {
                mouseover: function ($event) {
                  _vm.currentImgIndex = i
                },
              },
            })
          }),
          1
        ),
      ],
      2
    ),
    _c("div", { staticClass: "intro-text" }, [
      _c("div", { staticClass: "title" }, [
        _c("p", [_vm._v(_vm._s(_vm.detailData.title))]),
        _c("span", [_vm._v("月销5000+")]),
      ]),
      _c("div", { staticClass: "price" }, [
        _c("div", { staticClass: "row" }, [
          _c("p", { staticClass: "label" }, [_vm._v("价格")]),
          _c("p", { staticClass: "content red" }, [
            _vm._v(" ￥"),
            _c("span", { staticClass: "val" }, [
              _vm._v(_vm._s((_vm.detailData.listPrice / 100).toFixed(2))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "row" }, [
          _c("p", { staticClass: "label" }, [_vm._v("优惠券")]),
          _c("p", { staticClass: "content" }, [
            _c(
              "span",
              {
                staticClass: "coupon-tag",
                style:
                  "background-image: url(" +
                  require("@/assets/images/tags_coupon.png") +
                  ");",
              },
              [_vm._v("满130享9折")]
            ),
            _c(
              "span",
              {
                staticClass: "coupon-tag",
                style:
                  "background-image: url(" +
                  require("@/assets/images/tags_coupon.png") +
                  ");",
              },
              [_vm._v("满300享8折")]
            ),
            _c(
              "span",
              {
                staticClass: "coupon-tag",
                style:
                  "background-image: url(" +
                  require("@/assets/images/tags_coupon.png") +
                  ");",
              },
              [_vm._v("满600享7折")]
            ),
          ]),
        ]),
        _vm._m(0),
      ]),
      _c("div", { staticClass: "sku" }, [
        _c("div", { staticClass: "item" }, [
          _c("span", { staticClass: "label" }, [_vm._v("数量：")]),
          _c(
            "div",
            { staticClass: "tags" },
            [
              _c("el-input-number", {
                attrs: { min: 1, max: 999, precision: 0 },
                model: {
                  value: _vm.count,
                  callback: function ($$v) {
                    _vm.count = $$v
                  },
                  expression: "count",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "item" }, [
          _c(
            "div",
            {
              staticClass: "tags",
              on: {
                click: function ($event) {
                  return _vm.addCarts()
                },
              },
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "primary",
                  attrs: { icon: "el-icon-shopping-cart-1" },
                },
                [_vm._v("加入购物车")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("p", { staticClass: "label" }, [_vm._v("促销")]),
      _c("p", { staticClass: "content" }, [
        _c("span", { staticClass: "tag" }, [_vm._v("多买优惠")]),
        _c("span", { staticClass: "text" }, [
          _vm._v("满2件减15.00元；满3件减30元"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }