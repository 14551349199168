var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mail-list-breadcrumb" },
    [
      _c(
        "el-breadcrumb",
        { staticClass: "breadcrumb" },
        _vm._l(_vm.breadlitst, function (item) {
          return _c("el-breadcrumb-item", { key: item.path }, [
            _vm._v(_vm._s(item.title)),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }