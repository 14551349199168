var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _c("Breadcrumb"),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c("DetailImageAndInfor", {
            attrs: { "detail-data": _vm.detailData },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "detail-information" },
        [
          _c(
            "div",
            [
              _c("SearchComponent", {
                attrs: { "default-search-keywords": _vm.defaultSearchKeywords },
              }),
              _c("RecommendListComponent"),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "infor-detail-image", attrs: { shadow: "never" } },
            [
              _vm.content
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } })
                : _c("el-empty", { attrs: { description: "暂无详细资料" } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }