var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "search" }, [
      _c("i", { staticClass: "el-icon-search" }),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.searchKeywords,
            expression: "searchKeywords",
          },
        ],
        staticClass: "inp-search",
        attrs: { placeholder: _vm.defaultSearchKeywords },
        domProps: { value: _vm.searchKeywords },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.searchKeywords = $event.target.value
          },
        },
      }),
      _c(
        "a",
        {
          staticClass: "btn-search",
          attrs: { href: "javascript:;" },
          on: { click: _vm.$showNoAuthMsg },
        },
        [_vm._v("搜索")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }